import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

const BlogIndex = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata?.title || `Title`;

	return (
		<Layout location={location} title={siteTitle}>
			<Seo title="PrivacyPolicy" />
			<hr />
			<h2>プライバシーポリシー</h2>
			<div className="common-contents-block-wrapper">
				個人情報保護方針
				<br />
				有限会社グリーンスタジオ（以下「当社」）は、以下のとおり個人情報保護方針を定め、個人情報を保護するための管理体制を構築し、全従業員に個人情報保護の重要性の認識と取組を徹底させることにより、個人情報の保護を推進いたします。
			</div>
			<hr />
			<h2>個人情報の管理</h2>
			<div className="common-contents-block-wrapper">
				当社は、お客さまの情報を正確かつ最新の状態に保ち、不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、管理体制の整備・社員教育の徹底等の必要な措置を講じたうえ、適切な安全対策のもと、個人情報の厳重な管理を行ないます。
			</div>
			<hr />
			<h2>個人情報の利用目的</h2>
			<div className="common-contents-block-wrapper">
				お客さまからお預かりした個人情報は、当社において以下の目的で利用いたします。
				<br />
				　　・お客さまからのご意見・ご感想をいただくため お客さまからのお問合せや資料請求などに対応するため
				<br />
				　　・新しいサービス開発に関連する業務のため 電子メール配信サービスや出版物などの発送のため
				<br />
				　　・当社で取り扱っている商品やサービスに関する情報提供のため
				<br />
				　　・採用に関する情報提供、採用選考及びアンケート調査のため その他当社の事業に付帯・関連する事項のため
			</div>
			<hr />
			<h2>個人情報の第三者への開示・提供の禁止</h2>
			<div className="common-contents-block-wrapper">
				当社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
				<br />
				　　・お客さまの同意がある場合
				<br />
				　　・お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合
				<br />
				　　・法令に基づき開示することが必要である場合
			</div>
			<hr />
			<h2>個人情報の安全管理措置</h2>
			<div className="common-contents-block-wrapper">
				当社は、個人情報の正確性及び安全性確保のために、セキュリティに必要な対策を講じます。
			</div>
			<hr />
			<h2>従業者および委託先の監督について</h2>
			<div className="common-contents-block-wrapper">
				①当社は、安全管理措置の実施、その他の個人情報の適正な取り扱いを確保するため、当社の従業員に対し必要な教育研修を実施します。
				<br />
				②当社は、当社における業務遂行の過程において、個人情報の取り扱いの全部又は一部を第三者へ委託する場合があります。この場合、当社は、個人情報を適正に取り扱うと認められる業者を選定し、委託契約において、安全管理措置、秘密保持、再委託の条件その他の個人情報の取り扱いに関する事項について適正に定め、必要かつ適切な監督を実施します。
			</div>
			<hr />
			<h2>ご本人の照会</h2>
			<div className="common-contents-block-wrapper">
				お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応いたします。
			</div>
			<hr />
			<h2>法令、規範の遵守と見直し</h2>
			<div className="common-contents-block-wrapper">
				当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
			</div>
			<hr />
			<h2>お問い合せ</h2>
			<div className="common-contents-block-wrapper">
				当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。
				<br />
				有限会社グリーンスタジオ　藤川
			</div>
		</Layout>
	);
};

export default BlogIndex;

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
			nodes {
				excerpt
				fields {
					slug
				}
				frontmatter {
					date(formatString: "MMMM DD, YYYY")
					title
					description
				}
			}
		}
	}
`;
